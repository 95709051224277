import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyContainer from "../main_container";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

//API handling components
let API_URL = `https://csmtest.5techg.com/api/`;

export default class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      showUpdateModel: false,
      id: "",
      site_id: "",
      index: "",
      message: "",
      reminder_date: "2017-05-24",
      vehiclesData: null,
    };
    this.tmp = null;
    this.fetchPartiesData = this.fetchPartiesData.bind(this);
  }

  fetchPartiesData() {
    let url = API_URL;
    const query = this.props.site_id
      ? `SELECT * from feedback where site_id=${this.props.site_id}`
      : `SELECT * FROM feedback;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("vehicle data: ", res.data);
        this.setState({
          vehiclesData: res.data.map((val, index) => ({
            ...val,
            index: index + 1,
          })),
        });
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
      });
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;
    console.log(typeof this.state.reminder_date);
    const query = `UPDATE feedback SET message="${this.state.message}", reminder_date="${this.state.reminder_date}" WHERE id=${this.state.id};`;
    console.log(query);
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("Fedback updated successfully");
      })
      .catch((err) => {
        console.log("error while updating party data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO feedback(site_id, message, reminder_date) VALUES(${
      this.props.site_id ? this.props.site_id : this.state.site_id
    }, '${this.state.message}', "${this.state.reminder_date}")`;

    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("Feedback added successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `delete from feedback WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        this.props.refresh();
        this.props.toast.error("Feedback deleted successfully");
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.fetchPartiesData();
  }

  componentDidUpdate() {
    this.tmp = $("#feedback_table").DataTable({
      destroy: true,
      keys: true,
      order: this.tmp ? this.tmp.order() : [0, "asc"],
      buttons: [
        "copy",
        "csv",
        "excel",
        {
          extend: "pdf",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
        {
          extend: "print",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
      ],
    });
  }

  renderVehiclesData = () => {
    const parties = this.state.vehiclesData;

    if (parties == null) {
      return null;
    }

    return parties.map((feedback) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{feedback["index"]}</Badge>{" "}
          </td>
          <td align="center">{feedback["message"]}</td>
          <td align="center">{feedback["reminder_date"]}</td>
          <td align="center">
            <Button
              variant="contained"
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  id: feedback.id,
                  index: feedback.index,
                  site_id: feedback.site_id,
                  message: feedback.message,
                  reminder_date: feedback.reminder_date,
                });
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon icon={faPenAlt} style={{ color: "blue" }} />
            </Button>
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(feedback["id"]);
                }
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col>
                  <TextField
                    id="partyName"
                    label="Index"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.index}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="partyName"
                    label="Site Id"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.site_id}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="datetime-local"
                    label="Next appointment"
                    type="date"
                    value={this.state.reminder_date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      this.setState({
                        reminder_date: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Message"
                    variant="outlined"
                    className="m-2"
                    style={{ width: "95%" }}
                    defaultValue={this.state.message}
                    onChange={(e) =>
                      this.setState({
                        message: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
  render() {
    let dataLen = this.state.vehiclesData ? this.state.vehiclesData.length : 0;
    if (this.props.site_id) {
      return (
        <>
          <div className="container-fluid border m-0 p-1">
            <div class="btn-group" role="group" aria-label="Basic example">
              {console.log(this.state.vehiclesData, dataLen)}
              {dataLen < 21 ? (
                <Button
                  className="mt-1 mr-1 mb-3"
                  color="secondary"
                  variant="contained"
                  onClick={(e) => {
                    this.setState({ showAddModal: true });
                  }}
                >
                  add new Feedback
                </Button>
              ) : (
                ""
              )}
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={(e) =>
                  setTimeout(() => {
                    this.props.site_id
                      ? this.props.refresh()
                      : window.location.reload(false);
                  }, 50)
                }
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
            </div>

            {this.renderUpdatePartyModal()}
            <Modal
              show={this.state.showAddModal}
              onHide={(e) => this.setState({ showAddModal: false })}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New Feedback
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form noValidate autoComplete="off">
                  <div className="mt-3">
                    <Row>
                      <Col size="12">
                        <TextField
                          id="partyName"
                          label="Site Id"
                          variant="outlined"
                          className="m-2"
                          defaultValue={
                            this.props.site_id ? this.props.site_id : ""
                          }
                          onChange={(e) => {
                            this.setState({
                              site_id: e.target.value,
                            });
                          }}
                          disabled={this.props.site_id ? true : false}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="datetime-local"
                          label="Next appointment"
                          type="date"
                          value={this.state.reminder_date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            this.setState({
                              reminder_date: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          s
                          label="Message"
                          variant="outlined"
                          className="m-2"
                          style={{ width: "95%" }}
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              message: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="mt-2 mr-1">
                    <Btn1
                      style={{ float: "right" }}
                      onClick={(e) => {
                        this.setState({
                          showAddModal: false,
                        });
                        this.handleAddSubmit(e);
                      }}
                    >
                      Add
                    </Btn1>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="feedback_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Feedback Number</th>
                        <th>message</th>
                        <th>Remider date</th>
                        <th>Options</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </>
      );
    }
    return (
      <MyContainer path={["", "feedback"]}>
        <>
          <div className="container-fluid border m-0 p-1">
            <div class="btn-group" role="group" aria-label="Basic example">
              <Button
                className="mt-1 mr-1 mb-3"
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  this.setState({ showAddModal: true });
                }}
              >
                add new Feedback
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={(e) =>
                  setTimeout(() => {
                    this.props.site_id
                      ? this.props.refresh()
                      : window.location.reload(false);
                  }, 50)
                }
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
            </div>

            {this.renderUpdatePartyModal()}
            <Modal
              show={this.state.showAddModal}
              onHide={(e) => this.setState({ showAddModal: false })}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New Feedback
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form noValidate autoComplete="off">
                  <div className="mt-3">
                    <Row>
                      <Col size="12">
                        <TextField
                          id="partyName"
                          label="Site Id"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) => {
                            this.setState({
                              site_id: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="datetime-local"
                          label="Next appointment"
                          type="date"
                          value={this.state.reminder_date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) =>
                            this.setState({
                              reminder_date: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Message"
                          variant="outlined"
                          className="m-2"
                          style={{ width: "95%" }}
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              message: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="mt-2 mr-1">
                    <Btn1
                      style={{ float: "right" }}
                      onClick={(e) => {
                        this.setState({
                          showAddModal: false,
                        });
                        this.handleAddSubmit(e);
                      }}
                    >
                      Add
                    </Btn1>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="feedback_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Feedback Number</th>
                        <th>message</th>
                        <th>Reminder Date</th>
                        <th>Options</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </MyContainer>
    );
  }
}
