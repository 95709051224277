import React from "react";
import Chart from "chart.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { red } from "@material-ui/core/colors";

const datetmp = new Date();

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "2%",
    backgroundColor: "#cccccc",
  },
  root: {
    maxWidth: 345,
    width: "96%",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export function Chart3({ info }) {
  var chart3 = React.useRef(null);

  const donutData = {
    labels: ["converted", "not-converted"],
    datasets: [
      {
        data: info,
        backgroundColor: ["#f56954", "#00a65a"],
      },
    ],
  };

  const donutOptions = {
    maintainAspectRatio: false,
    responsive: true,
  };

  React.useEffect(() => {
    var donutChartCanvas = chart3;
    //Create pie or douhnut chart
    // You can switch between pie and douhnut using the method below.
    new Chart(donutChartCanvas, {
      type: "doughnut",
      data: donutData,
      options: donutOptions,
    });
  }, []);

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {/* PIE CHART */}
              <div className="card card-danger">
                <div className="card-header">
                  <h3 className="card-title">Donut Chart</h3>
                  <div className="card-tools">
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="collapse"
                    >
                      <i className="fas fa-minus" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-tool"
                      data-card-widget="remove"
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <canvas
                    id="pieChart"
                    style={{
                      minHeight: 250,
                      height: 250,
                      maxHeight: 250,
                      maxWidth: "100%",
                    }}
                    ref={(e) => (chart3 = e)}
                  />
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col (LEFT) */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}

export function Chart7({ info }) {
  var chart3 = React.useRef(null);
  const classes = useStyles();
  const donutData = {
    labels: ["converted", "not-converted"],
    datasets: [
      {
        data: info,
        backgroundColor: ["rgba(210, 214, 222, 0.7)", "rgba(60,141,188,0.7)"],
      },
    ],
  };

  const donutOptions = {
    maintainAspectRatio: false,
    responsive: true,
  };

  React.useEffect(() => {
    var donutChartCanvas = chart3;
    //Create pie or douhnut chart
    // You can switch between pie and douhnut using the method below.
    new Chart(donutChartCanvas, {
      type: "doughnut",
      data: donutData,
      options: donutOptions,
    });
  }, []);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        m={1}
        p={1}
        bgcolor="grey.300"
      >
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                D
              </Avatar>
            }
            title="Donut Chart"
            subheader="September 14, 2016"
          />
          <CardContent>
            <canvas
              id="donutChart"
              style={{
                minHeight: 250,
                height: 250,
                maxHeight: 250,
                maxWidth: "100%",
              }}
              ref={(e) => (chart3 = e)}
            />
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}

export function Chart4({ info }) {
  var chart4 = React.useRef(null);
  const classes = useStyles();
  const donutData = {
    labels: ["converted", "not-converted"],
    datasets: [
      {
        data: info,
        backgroundColor: ["rgba(210, 214, 222, 0.7)", "rgba(60,141,188,0.7)"],
      },
    ],
  };

  React.useEffect(() => {
    var pieChartCanvas = chart4;
    var pieData = donutData;
    var pieOptions = {
      maintainAspectRatio: false,
      responsive: true,
    };
    new Chart(pieChartCanvas, {
      type: "pie",
      data: pieData,
      options: pieOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              P
            </Avatar>
          }
          title="Pie Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="pieChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart4 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

export function Chart9({ info }) {
  var chart7 = React.useRef(null);
  const classes = useStyles();
  const labels = ["Converted", "Not Converted"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [info[0], info[1]],
        backgroundColor: [
          "rgba(0, 0, 0, 0.7)",
          "rgba(60,141,188,0.7)",
          "rgba(0, 0, 0, 0.5)",
        ],
      },
    ],
  };

  React.useEffect(() => {
    var polarAreaChartCanvas = chart7;
    var polarAreaData = data;
    var polarAreaOptions = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Chart.js Polar Area Chart",
        },
      },
    };
    new Chart(polarAreaChartCanvas, {
      type: "polarArea",
      data: polarAreaData,
      options: polarAreaOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              P
            </Avatar>
          }
          title="Polar Area Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="polarAreaChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart7 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

export function Chart10({ info }) {
  var chart8 = React.useRef(null);
  const classes = useStyles();
  const labels = ["Converted", "Not Converted"];
  const data = {
    labels: ["", "Not converted", "Converted", ""],
    datasets: [
      {
        label: "Dataset",
        data: [0, info[1], info[0], 0, 48, 20, 80],
        borderColor: "rgba(0, 0, 0, 0.8)",
        backgroundColor: "rgba(60,141,188,0.7)",
        fill: true,
        steppedLine: true,
        borderWidth: 1,
      },
    ],
  };

  React.useEffect(() => {
    var polarAreaChartCanvas = chart8;
    var polarAreaData = data;
    var polarAreaOptions = {
      maintainAspectRatio: false,
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          axis: "x",
        },
        plugins: {
          title: {
            display: true,
            text: "stepped line Chart",
          },
        },
      },
    };
    new Chart(polarAreaChartCanvas, {
      type: "line",
      data: polarAreaData,
      options: polarAreaOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              P
            </Avatar>
          }
          title="Steped Line Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="polarAreaChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart8 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

export function Chart11({ info }) {
  var chart9 = React.useRef(null);
  const classes = useStyles();
  const labels = ["Converted", "Not Converted"];
  const data = {
    labels: ["", "Not converted", "Converted", ""],
    datasets: [
      {
        label: "Dataset",
        data: [0, info[1], info[0], 0, 48, 20, 80],
        borderColor: "rgba(0,0,0,0.8)",
        backgroundColor: "rgba(60,141,188,0.7)",
        fill: true,
        borderWidth: 1,
      },
    ],
  };

  React.useEffect(() => {
    var polarAreaChartCanvas = chart9;
    var polarAreaData = data;
    var polarAreaOptions = {
      maintainAspectRatio: false,
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          axis: "x",
        },
        plugins: {
          title: {
            display: true,
            text: "stepped line Chart",
          },
        },
      },
    };
    new Chart(polarAreaChartCanvas, {
      type: "line",
      data: polarAreaData,
      options: polarAreaOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              P
            </Avatar>
          }
          title="Smooth line Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="polarAreaChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart9 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

export function Chart5({ info }) {
  var chart5 = React.useRef(null);
  const classes = useStyles();
  const areaChartData = {
    labels: ["is_converted"],
    datasets: [
      {
        label: "converted",
        backgroundColor: "rgba(60,141,188,0.9)",
        borderColor: "rgba(60,141,188,0.8)",
        pointRadius: false,
        pointColor: "#3b8bba",
        pointStrokeColor: "rgba(60,141,188,1)",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(60,141,188,1)",
        data: [info[0]],
      },
      {
        label: "not converted",
        backgroundColor: "rgba(210, 214, 222, 1)",
        borderColor: "rgba(210, 214, 222, 1)",
        pointRadius: false,
        pointColor: "rgba(210, 214, 222, 1)",
        pointStrokeColor: "#c1c7d1",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(220,220,220,1)",
        data: [info[1]],
      },
    ],
  };

  React.useEffect(() => {
    var barChartCanvas = chart5;
    var barChartData = { ...areaChartData };
    var temp0 = areaChartData.datasets[0];
    var temp1 = areaChartData.datasets[1];
    barChartData.datasets[0] = temp0;
    barChartData.datasets[1] = temp1;

    var barChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      datasetFill: false,
    };

    new Chart(barChartCanvas, {
      type: "bar",
      data: barChartData,
      options: barChartOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              B
            </Avatar>
          }
          title="Bar Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="barChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart5 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

export function Chart8({ info }) {
  var chart5 = React.useRef(null);
  const classes = useStyles();
  const labels = ["not converted", "converted"]; //Utils.months({ count: 7 });
  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: [info[1], info[0], 0, Math.max(info[0], info[1]) * 1.1],
        backgroundColor: ["rgba(210, 214, 222, 0.7)", "rgba(60,141,188,0.7)"],
        borderColor: ["rgba(190, 194, 202, 1)", "rgba(60,141,188, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const areaChartData1 = {
    labels: ["not converted", "convertd"],
    datasets: [
      {
        label: "is_converted",
        backgroundColor: ["rgba(60,141,188,0.9)", "rgba(210, 214, 222, 1)"],
        borderColor: ["rgba(60,141,188,0.8)", "rgba(210, 214, 222, 1)"],
        pointRadius: false,
        pointColor: ["#3b8bba", "ragb(210, 214, 222, 1)"],
        pointStrokeColor: ["rgba(60,141,188,1), #c1c7d1"],
        pointHighlightFill: ["#fff", "#fff"],
        pointHighlightStroke: ["rgba(60,141,188,1)", "rgba(220,220,220,1)"],
        borderWith: 1,
        data: [info[0], info[1]],
      },
    ],
  };

  const areaChartData = data;

  React.useEffect(() => {
    var barChartCanvas = chart5;
    var barChartData = { ...areaChartData };
    var temp0 = areaChartData.datasets[0];
    //var temp1 = areaChartData.datasets[1];
    barChartData.datasets[0] = temp0;
    //barChartData.datasets[1] = temp1;

    var barChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      datasetFill: false,
    };

    new Chart(barChartCanvas, {
      type: "bar",
      data: barChartData,
      options: barChartOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              B
            </Avatar>
          }
          title="Bar Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="barChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart5 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

export function Chart6({ info }) {
  var chart6 = React.useRef(null);
  const classes = useStyles();
  const areaChartData = {
    labels: ["is converted"],
    datasets: [
      {
        label: "Not Coverted",
        backgroundColor: "rgba(210, 214, 222, 1)",
        borderColor: "rgba(210, 214, 222, 1)",
        pointRadius: false,
        pointColor: "rgba(210, 214, 222, 1)",
        pointStrokeColor: "#c1c7d1",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(220,220,220,1)",
        data: [info[1]],
      },
      {
        label: "Converted",
        backgroundColor: "rgba(60,141,188,0.9)",
        borderColor: "rgba(60,141,188,0.8)",
        pointRadius: false,
        pointColor: "#3b8bba",
        pointStrokeColor: "rgba(60,141,188,1)",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(60,141,188,1)",
        data: [info[0]],
      },
    ],
  };

  React.useEffect(() => {
    var barChartData = { ...areaChartData };
    var stackedBarChartCanvas = chart6;
    var stackedBarChartData = { ...barChartData };

    var stackedBarChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };

    new Chart(stackedBarChartCanvas, {
      type: "bar",
      data: stackedBarChartData,
      options: stackedBarChartOptions,
    });
  }, []);

  return (
    <Box display="flex" justifyContent="center" m={1} p={1} bgcolor="grey.300">
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              S
            </Avatar>
          }
          title="Stacked Bar Chart"
          subheader="September 14, 2016"
        />
        <CardContent>
          <canvas
            id="stackedBarChart"
            style={{
              minHeight: 250,
              height: 250,
              maxHeight: 250,
              maxWidth: "100%",
            }}
            ref={(e) => (chart6 = e)}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
