import React, { Component } from "react";
import { AppBar, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import MyContainer from "../main_container";
import BirthdayRemainder from "./BirthdayRemainder";
import AniversaryRemainder from "./AniversaryRemainder";
export default class Remainder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "1",
    };
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <MyContainer path={["", "Remainder"]}>
        <>
          <TabContext
            value={this.state.value}
            className="container-fluid border m-0 p-0 main"
          >
            <AppBar position="static" color="default">
              <TabList
                onChange={this.handleTabs}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Birthday" value="1" />
                <Tab label="Aniversary" value="2" />
              </TabList>
            </AppBar>
            <TabPanel value="1" className="m-0 p-0">
              <BirthdayRemainder toast={this.props.toast} />
            </TabPanel>
            <TabPanel value="2" className="m-0 p-0">
              <AniversaryRemainder toast={this.props.toast} />
            </TabPanel>
          </TabContext>
        </>
      </MyContainer>
    );
  }
}
