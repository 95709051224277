import React from "react";
import Logo from "./img/AdminLTELogo.jpg";
import FullLogo from "./img/AdminLTELogo2.jpg";
import { Link } from "react-router-dom";
import zIndex from "@material-ui/core/styles/zIndex";

export default function Menu() {
  const [o, setO] = React.useState(false);
  return (
    <div>
      {/* <span
        className="menu_image_hover"
        style={{
          position: "absolute",
          display: "hidden",
          width: "45%",
          minWidth: 50,
          height: "auto",
          minHeight: 50,
          left: "25%",
          top: "2.5%",
          backgroundColor: "transparent",
          zIndex: 1100,
        }}
      >
        <img
          src={FullLogo}
          alt="AdminLTE Logo"
          className="shadow-lg"
          style={{
            width: "100%",
            borderRadius: "50%",
            opacity: o ? "1" : "0",
            border: "2px solid #000",
          }}
        />
      </span> */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <div className="brand-link" style={{ position: "relative" }}>
          <img
            src={Logo}
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
            onMouseEnter={(e) => setO(true)}
            onMouseLeave={(e) => setO(false)}
          />
          <span className="brand-text font-weight-light">TulTech</span>
        </div>
        <div
          className="sidebar os-host os-theme-light os-host-overflow
                    os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden
                    os-host-transition"
          style={{ paddingTop: "2em" }}
        >
          <div className="os-resize-observer-host observed">
            <div
              className="os-resize-observer"
              style={{ left: 0, right: "auto" }}
            />
          </div>
          <div
            className="os-size-auto-observer observed"
            style={{ height: "calc(100% + 1px)", float: "left" }}
          >
            <div className="os-resize-observer" />
          </div>
          <div
            className="os-content-glue"
            style={{ margin: "0px -8px", width: 249, height: 605 }}
          />
          <div className="os-padding">
            <div
              className="os-viewport os-viewport-native-scrollbars-invisible"
              style={{ overflowY: "scroll" }}
            >
              <div
                className="os-content"
                style={{ padding: "0px 8px", height: "100%", width: "100%" }}
              >
                {false ? (
                  <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                      <input
                        className="form-control form-control-sidebar"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-sidebar">
                          <i className="fas fa-search fa-fw" />
                        </button>
                      </div>
                    </div>
                    <div className="sidebar-search-results">
                      <div className="list-group">
                        <a href="#" className="list-group-item">
                          <div className="search-title">
                            <strong className="text-light" />
                            N
                            <strong className="text-light" />
                            o
                            <strong className="text-light" />
                            <strong className="text-light" />
                            e
                            <strong className="text-light" />
                            l
                            <strong className="text-light" />
                            e
                            <strong className="text-light" />
                            m
                            <strong className="text-light" />
                            e
                            <strong className="text-light" />
                            n
                            <strong className="text-light" />
                            t
                            <strong className="text-light" />
                            <strong className="text-light" />
                            f
                            <strong className="text-light" />
                            o
                            <strong className="text-light" />
                            u
                            <strong className="text-light" />
                            n
                            <strong className="text-light" />
                            d
                            <strong className="text-light" />
                            !
                            <strong className="text-light" />
                          </div>
                          <div className="search-path" />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    <li className="nav-item">
                      <Link to="/">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-chart-line"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            Dashboard
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/remainder">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-bell"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            Remainder
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/site/">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-hard-hat"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            Site
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/vehicles">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fas fa-tractor"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            vehicles
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/feedback/">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-comments"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            Feedback
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/user/">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-user"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            user
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/completed">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-check"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            Completed
                          </p>
                        </button>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/partyManager">
                        <button className="btn nav-link">
                          <i
                            className="nav-icon fa fa-users"
                            style={{
                              color: "#c2c7d0",
                              float: "left",
                            }}
                          />
                          <p
                            style={{
                              float: "left",
                              color: "#c2c7d0",
                              margin: 0,
                              whiteSpace: "normal",
                            }}
                          >
                            Party Manager
                          </p>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div
            className="os-scrollbar os-scrollbar-horizontal
                        os-scrollbar-unusable os-scrollbar-auto-hidden"
          >
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ width: "100%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div
            className="os-scrollbar os-scrollbar-vertical
                        os-scrollbar-auto-hidden"
          >
            <div className="os-scrollbar-track">
              <div
                className="os-scrollbar-handle"
                style={{ height: "27.76%", transform: "translate(0px, 0px)" }}
              />
            </div>
          </div>
          <div className="os-scrollbar-corner" />
        </div>
      </aside>
    </div>
  );
}
