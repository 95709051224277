import React from "react";

export default function Heade() {
  return (
    <div>
      <nav
        className="main-header navbar navbar-expand navbar-white navbar-light"
        style={{
          backgroundColor: "#3c8dbc",
          color: "white",
          paddingTop: "1.5em",
          paddingBottom: "0.5em",
        }}
      >
        {/* Left navbar links */}
        <ul className="navbar-nav" style={{ color: "white" }}>
          <li className="nav-item">
            <span className="nav-link" data-widget="pushmenu" role="button">
              <i className="fas fa-bars" style={{ color: "white" }} />
            </span>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          <li className="nav-item">
            <span className="nav-link" data-widget="fullscreen" role="button">
              <i
                className="fas fa-expand-arrows-alt"
                style={{ color: "white" }}
              />
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
}
