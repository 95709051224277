import React, { Component } from "react";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";

import axios from "axios";

let API_URL = `https://csmtest.5techg.com/api/`;

export default class PartyManagerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeName: "",
      activeMobile: "",
      activeAddress: "",
      activeType: "",
      activeDateOfBirth: "",
      activeAniversary: "",
    };
  }

  handleAddSubmit(e) {
    e.preventDefault();

    console.log(this.state.activeName);
    console.log(this.state.activeMobile);
    console.log(this.state.activeAddress);
    console.log(this.state.activeType);
    console.log(this.state.activeDateOfBirth);
    console.log(this.state.activeAniversary);
    let url = API_URL;

    var date = new Date();
    var current_date =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();

    const query = `INSERT INTO party_manager (name, address, mobile, dateOfBirth, aniversary,type,status,date_added,last_modified) VALUES('${this.state.activeName}','${this.state.activeAddress}','${this.state.activeMobile}','${this.state.activeDateOfBirth}','${this.state.activeAniversary}','${this.props.type}',1,'${current_date}', '${current_date}' );`;

    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.toast.success(
          `${this.props.type} details added successfully`
        );
        this.props.refresh();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <Modal
        show={this.props.showAddModal}
        onHide={this.props.handleCloseAddModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New {this.props.type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col size="12">
                  <TextField
                    id="constructorName"
                    label={`${this.props.type} Name`}
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    onChange={(e) => {
                      this.setState({
                        activeName: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <TextField
                    id="mobile"
                    label="Mobile"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    onChange={(e) =>
                      this.setState({
                        activeMobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    s
                    label="Address"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    onChange={(e) =>
                      this.setState({
                        activeAddress: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <FormControl
                    variant="filled"
                    style={{
                      width: "100%",
                    }}
                    className="mt-2 ml-2 pr-3"
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Type
                    </InputLabel>

                    <Select
                      sx={{ width: "100%" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="type"
                      value={this.state.activeType}
                      onChange={(e) =>
                        this.setState({
                          activeType: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={`${this.props.type}`}>
                        {this.props.type}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="dateOfbirth"
                    label="Date of Birth"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className="mb-3 ml-2 mt-3 pr-3"
                    defaultValue={this.state.activeDateOfBirth}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        activeDateOfBirth: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="aniversary"
                    label="Aniversary"
                    type="date"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    className="mb-3 ml-2 mt-3 pr-3"
                    defaultValue={this.state.activeAniversary}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        activeAniversary: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.props.handleCloseAddModal();
                  this.handleAddSubmit(e);
                }}
              >
                Add
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}
