const MyContainer = ({ children, path }) => (
  <div>
    <div className="content-wrapper" style={{ minHeight: 549 }}>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">
                {path[1]
                  .split("_")
                  .map((val) => val.slice(0, 1).toUpperCase() + val.slice(1))
                  .join(" ")}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {path.map((item, index) => (
                  <li
                    key={"breadcrumb" + index.toString()}
                    className="breadcrumb-item"
                  >
                    {item}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <section className="col-lg-12 connectedSortable ui-sortable">
              {children}
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default MyContainer;
