import "./App.css";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import { useState } from "react";
import { Switch, HashRouter as Router, Route } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "bootstrap/dist/css/bootstrap.css";
import User from "./components/user/User";
import Completed from "./components/completed/completed";
import Feedback from "./components/feedback/Feedback";
import Vehicles from "./components/vehicles/Vehicles";
import VehicleReading from "./components/vehicle_reading/Vehicle_reading";
import Site from "./components/site/Site";
import Test from "./components/test/Test";
import Graph from "./components/graphs/Graph";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PartyManager from "./components/party_manager/PartyManager";
import Remainder from "./components/remainder/Remainder";
import Login from "./components/login/Login";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
// import Main from "./Main";

export default function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState("user123");
  const [oldData, setOldData] = useState([[0, 0], 0]);
  const [r, setR] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") == "true" ? true : false
  );


  return (
    <Router>     
      <div className="wrapper">
        {/* <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: 500,
                height: 500,
              }}
            >
              <button style={{ zIndex: 50 }}>5TechG</button>
            </div> */}
        {!isLoggedIn && <Login />}
        {isLoggedIn && (

            <div className="container-fuild m-0 p-2">
              <Header />
            <Menu />
              <Switch>
                <Route path="/mainDashboard" exact>
                  <Graph refresh={() => setR(r + 1)} key={Date.now()} />
                </Route>
                <Route path="/User" exact>
                  <User
                    refresh={() => setR(r + 1)}
                    key={Date.now()}
                    toast={toast}
                  />
                </Route>
                <Route path="/remainder" exact>
                  <Remainder toast={toast} />
                </Route>
                <Route path="/completed" exact>
                  <Completed />
                </Route>
                <Route path="/partyManager" exact>
                  <PartyManager toast={toast} />
                </Route>
                <Route path="/Site" exact>
                  <Site />
                </Route>
                <Route path="/test" exact>
                  <Test />
                </Route>
                <Route path="/Feedback" exact>
                  <Feedback
                    refresh={() => setR(r + 1)}
                    key={Date.now()}
                    toast={toast}
                  />
                </Route>
                <Route path="/Vehicles/" exact>
                  <Vehicles />
                </Route>
                <Route path="/Vehicle_reading" exact>
                  <VehicleReading />
                </Route>
                <Route path="/" exact>
                  <Graph
                    refresh={(data) => {
                      setOldData([data, oldData[1] + 1]);
                    }}
                    oldInfo={oldData[0]}
                    key={Date.now()}
                  />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
          </div>
        )}
      </div>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
    </Router >
  );
}

const NotFound = () => (
  <div className="container">
    <div>Page Not Found</div>
  </div>
);
