import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  formatMs,
} from "@material-ui/core";
import {
  faPenAlt,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";

// Datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;

var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// let API_URL = `http://localhost:5000/api`;
let API_URL = `https://csmtest.5techg.com/api/`;

export default class AllPartyManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "1",
      allData: [],
      showAddModal: false,
      activeName: "",
      activeMobile: "",
      activeAddress: "",
      activeType: "",
      activeDateOfBirth: "",
      activeAniversary: "",
    };
  }

  formatDate(date_param) {
    var format_date = new Date(date_param);

    var day = format_date.getDate();
    var month = format_date.getMonth() + 1;

    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;

    return format_date.getFullYear() + "-" + month + "-" + day;
  }

  refresh() {
    window.location.reload(false);
  }

  fetchData() {
    let url = API_URL;
    const query = `SELECT * FROM party_manager WHERE status=1;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("all data: ", res.data);
        this.setState({ allData: res.data });
      })
      .catch((err) => {
        console.log("all data error: ", err);
      });
  }

  handleTabs(event, newValue) {
    this.setState({ value: newValue });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    console.log("Inside Component Did Update");
    const title = " List";
    $("#constructor_table").DataTable({
      destroy: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          },
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          },
        },
      ],
    });
  }

  renderData = () => {
    const party_managers = this.state.allData;

    if (party_managers == null) {
      return null;
    }

    return party_managers.map((party_manager) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{party_manager["id"]}</Badge>{" "}
          </td>
          <td align="center">{party_manager["name"]}</td>
          <td align="center">{party_manager["mobile"]}</td>
          <td align="center">{party_manager["address"]}</td>
          <td align="center">{party_manager["type"]}</td>
          <td align="center">
            {party_manager["dateOfBirth"] == null
              ? "-"
              : this.formatDate(party_manager["dateOfBirth"])}
          </td>
          <td align="center">
            {party_manager["aniversary"] == null
              ? "-"
              : this.formatDate(party_manager["aniversary"])}
          </td>
          {/* <td align="center">
            <Button
              color="secondary"
              variant="contained"
                onClick={(e) => {
                  this.setState({
                    activePartyId: party_manager["id"],
                    activePartyName: party_manager["name"],
                    activePartyMobile: party_manager["mobile"],
                    activePartyAddress: party_manager["address"],
                    activePartyType: party_manager["type"],
                    showUpdateModal: true,
                  });
                }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>
            <Button
              className="mx-1"
              color="danger"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(party_manager["id"]);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td> */}
        </tr>
      );
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid border m-0 p-0 main">
          <div className="m-0 p-0">
            <div className="container-fluid border m-0 p-1">
              <div class="btn-group" role="group" aria-label="Basic example">
                {/* <Button
                  className="mt-1 mr-1 mb-3"
                  color="secondary"
                  variant="contained"
                  onClick={(e) => {
                    this.setState({ showAddModal: true });
                  }}
                >
                  Add New 
                </Button> */}
                <Button
                  color="primary"
                  variant="contained"
                  className="mt-1 mr-1 mb-3 ml-5"
                  onClick={this.refresh}
                >
                  <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                </Button>
              </div>
            </div>

            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="constructor_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Id</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Address</th>
                        <th>Type</th>
                        <th>Date of Birth</th>
                        <th>Aniversary</th>
                        {/* <th>Options</th> */}
                      </tr>
                    </thead>
                    <tbody>{this.renderData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
