import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

// Datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;

var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

let API_URL = `https://csmtest.5techg.com/api/`;
// let API_URL = `http://localhost:5000/api`;

export default class AniversaryRemainder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aniversaryData: [],
    };
  }

  refreshAniversaryRemainders() {
    window.location.reload(false);
  }

  formatDate(date_param) {
    var format_date = new Date(date_param);

    var day = format_date.getDate();
    var month = format_date.getMonth();

    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;

    return format_date.getFullYear() + "-" + month + "-" + day;
  }

  getDate() {
    var format_date = new Date();

    var day = format_date.getDate();
    var month = format_date.getMonth() + 1;

    if (month <= 9) month = "0" + month;
    if (day <= 9) day = "0" + day;

    var final_date = format_date.getFullYear() + "-" + month + "-" + day;
    console.log("Final Date: ", final_date);
    return final_date;
  }

  fetchAniversaryData() {
    let url = API_URL;
    const query = `SELECT * FROM party_manager WHERE status=1 AND DATE_FORMAT(aniversary, '%m-%d')=DATE_FORMAT('${this.getDate()}', '%m-%d');`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("aniversary data: ", res.data);
        this.setState({ aniversaryData: res.data });
      })
      .catch((err) => {
        console.log("aniversary data error: ", err);
      });
  }

  componentDidMount() {
    this.fetchAniversaryData();
  }

  componentDidUpdate() {
    console.log("Inside Component Did Update");
    const title = " List";
    $("#aniversary_remainder").DataTable({
      destroy: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          },
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6],
          },
        },
      ],
    });
  }

  renderAniversaryData = () => {
    const aniversaries = this.state.aniversaryData;

    if (aniversaries == null) {
      return null;
    }

    return aniversaries.map((aniversary) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{aniversary["id"]}</Badge>{" "}
          </td>
          <td align="center">{aniversary["name"]}</td>
          <td align="center">{aniversary["mobile"]}</td>
          <td align="center">{aniversary["address"]}</td>
          <td align="center">{aniversary["type"]}</td>
          <td align="center">
            {aniversary["dateOfBirth"] == null
              ? "-"
              : this.formatDate(aniversary["dateOfBirth"])}
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <>
        <div className="container-fluid border m-0 p-0 main">
          <div className="m-0 p-0">
            <div className="container-fluid border m-0 p-1">
              <div class="btn-group" role="group" aria-label="Basic example">
                <Button
                  color="primary"
                  variant="contained"
                  className="mt-1 mr-1 mb-3 ml-5"
                  onClick={this.refreshAniversaryRemainders}
                >
                  <FontAwesomeIcon icon={faSyncAlt} size="2x" />
                </Button>
              </div>
            </div>

            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="aniversary_remainder"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Id</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Address</th>
                        <th>Type</th>
                        <th>Date of Birth</th>
                        {/* <th>Options</th> */}
                      </tr>
                    </thead>
                    <tbody>{this.renderAniversaryData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
