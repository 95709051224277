import React, { Component } from "react";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import MyContainer from "../main_container";
import { AppBar, Tab } from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import ArchitectPartyManager from "./ArchitectPartyManager";
import EngineerPartyManager from "./EngineerPartyManager";
import CentringMistryPartyManager from "./CentringMistryPartyManager";
import BandhkaamMistryPartyManager from "./BandhkaamMistryPartyManager";
import ContractorPartyManager from "./ContractorPartyManager";
import AllPartyManager from "./AllPartyManager";

export default class PartyManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "1",
    };
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <MyContainer path={["", "PartyManager"]}>
        <>
          <TabContext
            value={this.state.value}
            className="container-fluid border m-0 p-0 main"
          >
            <AppBar position="static" color="default">
              <TabList
                onChange={this.handleTabs}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="All" value="1" />
                <Tab label="Contractor" value="2" />
                <Tab label="Engineer" value="3" />
                <Tab label="Architect" value="4" />
                <Tab label="Centring Mistry" value="5" />
                <Tab label="Bandhkaam Mitry" value="6" />
              </TabList>
            </AppBar>
            <TabPanel value="1" className="m-0 p-0">
              <AllPartyManager toast={this.props.toast} />
            </TabPanel>
            <TabPanel value="2" className="m-0 p-0">
              <ContractorPartyManager toast={this.props.toast} />
            </TabPanel>
            <TabPanel value="3" className="m-0 p-0">
              <EngineerPartyManager toast={this.props.toast} />
            </TabPanel>
            <TabPanel value="4" className="m-0 p-0">
              <ArchitectPartyManager toast={this.props.toast} />
            </TabPanel>
            <TabPanel value="5" className="m-0 p-0">
              <CentringMistryPartyManager toast={this.props.toast} />
            </TabPanel>
            <TabPanel value="6" className="m-0 p-0">
              <BandhkaamMistryPartyManager toast={this.props.toast} />
            </TabPanel>
          </TabContext>
        </>
      </MyContainer>
    );
  }
}
