import React, { useEffect } from 'react'
import "./Login.css"
import { Route, Router } from 'react-router-dom/cjs/react-router-dom.min'
import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Form, Button } from 'react-bootstrap'
import { Label } from 'reactstrap'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Main from '../../Main'
import logo from '../../img/AdminLTELogo.jpg'

function Login() {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    // var authenticated = (toggleLogin) => {
    //     toggleLogin();
    // }
    // var Username = "admin@gmail.com";
    // var Password = "admin@123";



    // const redirect = () => {
    //     if (isAuthenticated) {
    //         console.log("redirecting...");
    //         // return (
    //         //     <Redirect
    //         //         to={{
    //         //             pathname: "/main",
    //         //         }}
    //         //     />
    //         //     <Router>
    //         //         <Route path="/main">
    //         //             <Main />
    //         //         </Route>
    //         //     </Router>


    //         // );
    //     }
    // }

    let user = { userType: 0, isLoggedIn: false }
    console.log(user);

    const handleSubmit = () => {
        // if (email == Username && password == Password) {
        //     setIsAuthenticated(true);
        //     redirect();
        // } else {
        //     toast("Incorrect Username or Password");
        // }

        if (username === "admin@gmail.com" && password === "admin@123") {
            user = { userType: 1, isLoggedIn: true }
        }
        localStorage.setItem("isLoggedIn", user.isLoggedIn)
        localStorage.setItem("userType", user.userType)

        window.location.reload(false);
    }


    return (
        <div className='main py-5 my-5'>
            {/* {redirect()} */}
            <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4'>
                    <img className='logo' src={logo}></img>
                    <h3>TULTECH ADMIN PANEL</h3>
                    <Card className='card m-3 p-3'>
                        <FontAwesomeIcon
                            size='3x'
                            className='icon mb-2'
                            icon={faUser}
                        />
                        <h5>Admin Login</h5>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Username'
                                    onChange={(e) => setUsername(e.target.value)}
                                    // value={email}
                                    required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    // value={password}
                                    required
                                />
                            </Form.Group>
                            <div className="">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="remember"
                                        value="true"
                                        id="rememberMe"
                                    />
                                    <label className="form-check-label" for="rememberMe">
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <Button className='button my-2' variant='primary btn-block' type='submit'>
                                Log In
                            </Button>
                            <div className="">
                                <p className="small mb-0">
                                    Don't have account?{" "}
                                    <a href="pages-register.html">Create an account</a>
                                </p>
                            </div>
                        </Form>
                    </Card>
                </div>
                <div className='col-md-4'></div>
            </div>

        </div>
    )
}

export default Login